import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import styles from '../../SingleStory.module.sass'
import Button from '@mui/material/Button'
import { getConfig } from '../../../../modules/Config/selectors'
import { actions } from '../../../../modules/SingleStory/reducer'
import usePiwik from '../../../../hooks/usePiwik'

const UpcomingAndAuthenticated = ({
  user,
  productCode = null,
  contactAction = () => {},
  articleId = ''
}) => {
  const dispatch = useDispatch()
  const { handleTrackingWithArticleIdProp } = usePiwik()
  const notifyMeSettings =
    useSelector(getConfig('notify_me_settings'), shallowEqual) || {}
  const newsletterUuid = notifyMeSettings?.newsletter_notify_me?.newsletter_uuid

  const userIsNotified = user?.notifyMe?.find(
    item =>
      item?.preview_article_ids?.includes(productCode) &&
      item?.newsletter_uuid === newsletterUuid
  )

  const notifyUpdate = () => {
    const notified = !userIsNotified

    dispatch(
      actions.requestSimpleNotifyMeSubscribe([
        {
          newsletterUuid,
          articleId: productCode
        }
      ])
    )
    handleTrackingWithArticleIdProp(
      'article',
      'button-click',
      !notified
        ? 'notify-me-of-this-report'
        : 'cancel-notify-me-of-this-report',
      articleId
    )
  }

  const handleButtonClick = () => {
    handleTrackingWithArticleIdProp(
      'article',
      'button-click',
      'contact-me-about-this-report',
      articleId
    )
    contactAction('contact-me-about-this-report')
  }

  return (
    <>
      <Button
        className={
          userIsNotified ? styles.notifyActiveButton : styles.notifyButton
        }
        variant="contained"
        onClick={notifyUpdate}
      >
        {userIsNotified
          ? '✓ You will be notified of this report'
          : 'Notify me of this report'}
      </Button>
      <Button
        className={styles.contactButton}
        onClick={handleButtonClick}
        variant="contained"
      >
        Contact me about this report
      </Button>
    </>
  )
}

UpcomingAndAuthenticated.propTypes = {
  user: PropTypes.object,
  contactAction: PropTypes.func,
  productCode: PropTypes.string,
  articleId: PropTypes.string
}

export default UpcomingAndAuthenticated
