import _get from 'lodash/get'
import _set from 'lodash/set'
import dayjs from 'dayjs'
import siteIds from '../constants/siteIds'
import { siteName } from '../../../utils/oneCodeBase'

const tagMap = [
  'type',
  'fw_company',
  'fw_job_position',
  'fw_product',
  'fw_therapeutic_category',
  'drug_classes',
  'fw_conference',
  'conditions',
  'fw_regulatory_qualifier',
  'fw_regulatory_market',
  'fw_business_area',
  'fw_medical_conference',
  'fw_story_watch',
  'fw_report_stakeholders',
  'fw_report_functions_issues',
  'fw_report_therapy_areas',
  'fw_report_brand',
  'fw_report_disease_areas'
]

const lastUpdatedKeysOrder = [
  '1_day',
  '1_week',
  '1_month',
  '2_month',
  '3_month',
  '4_month',
  '5_month',
  '6_month'
]

const REDIRECT_TO_TAGS_PAGE_STATUS_CODE = 9000

// Function to escape special characters
function escapeElasticsearchQuery(query) {
  query.replace(/[\\]/g, '\\\\$&') // Escape back slash
  return query.replace(/[/#,$~%.':*?{}]/g, '\\$&') // Escape special characters
}

const MERGED__KOL_VIEWS_TYPES = ['KOL Views Results', 'KOL Views']
const MERGED_PHYSICIAN_VIEWS_TYPES = [
  'Physician Views Poll Results',
  'Physician Views'
]

const MERGEABLE_TYPES = {
  'KOL Views': MERGED__KOL_VIEWS_TYPES,
  'Physician Views': MERGED_PHYSICIAN_VIEWS_TYPES
}

class SearchParser {
  constructor(StoryParser, LabelReplacer, ConfigRepository) {
    this.storyParser = StoryParser
    this.labelReplacer = LabelReplacer
    this.configRepository = ConfigRepository
  }

  getTypesFromUI(filters) {
    const types = _get(filters, 'filters.type', [])

    let typesValue = types || []
    for (const type of types) {
      if (type in MERGEABLE_TYPES) {
        const mergedType = MERGEABLE_TYPES[type]
        typesValue = [...typesValue, ...mergedType]
      } else {
        typesValue = [...typesValue, type]
      }
    }
    const cleanedTypes = new Set(typesValue)
    typesValue = Array.from(cleanedTypes)

    const siteId = process.env.REACT_APP_SITE_ID
    if (
      siteIds.SITE_ID_PHARMA === siteId ||
      siteIds.SITE_ID_HEALTHTECH === siteId
    ) {
      return typesValue.map(v => this.labelReplacer.getForML(v, 'type'))
    }
    return typesValue
  }

  encodeSearchBody(config, encodeElasticChars = false) {
    const { filters, showMedicalAbstracts } = config
    const searchBody = {
      showMedicalAbstracts
    }
    let query = _get(filters, 'query')
    if (!query) {
      query = null
    } else if (encodeElasticChars) {
      query = escapeElasticsearchQuery(query)
    }

    // Add validation to discard specific article types
    let exclude_article_types = _get(filters, 'exclude_article_types')
    if (exclude_article_types && exclude_article_types.length > 0) {
      _set(searchBody, 'exclude_article_types', exclude_article_types)
    }

    if (filters?.all_destinations) {
      _set(searchBody, 'all_destinations', filters?.all_destinations)
    }

    if (filters?.base_query) {
      _set(searchBody, 'base_query', filters?.base_query)
    }

    _set(searchBody, 'query_string', query)
    const types = this.getTypesFromUI(filters)
    _set(searchBody, 'type', types)
    _set(searchBody, 'sort_by', _get(filters, 'sort', 'publication_date'))
    _set(searchBody, 'from', _get(filters, 'from', 0))
    _set(searchBody, 'size', _get(filters, 'size', 10))
    _set(searchBody, 'fields_exist', _get(filters, 'fieldsExist'))
    const relatedToArticleIds = _get(filters, 'related_to_article_ids')
    if (relatedToArticleIds) {
      _set(
        searchBody,
        'related_to_article_id',
        relatedToArticleIds.map(id => parseInt(id, 10))
      )
    }
    // Last updated filter
    let lastUpdated = _get(filters, 'filters.publication_date', null)
    if (typeof lastUpdated === 'string')
      lastUpdated = lastUpdated.replace('_', ' ')
    _set(searchBody, 'publication_date', lastUpdated)

    // Last updated filter
    const publicationDateEnd = _get(
      filters,
      'filters.publication_date_end',
      null
    )
    _set(searchBody, 'publication_date_end', publicationDateEnd)

    const batchSize = _get(filters, 'batch_size', null)
    _set(searchBody, 'batch_size', batchSize)

    const contentSettings =
      this.configRepository.getStateConfig('content_settings')
    const validTagCategories = contentSettings?.valid_tag_categories ?? []
    for (let key of validTagCategories) {
      const newkey = key === 'drug_classes' ? 'fw_therapeutic_category' : key
      const val = _get(filters, `filters.${newkey}`, []).map(v =>
        this.labelReplacer.getForML(v, newkey)
      )
      if (val.length > 0) _set(searchBody, `tags.${newkey}`, val)
    }
    if (!searchBody.tags) {
      _set(searchBody, 'tags', {})
    }
    if (filters.titleBoost) {
      const terms = _get(filters, 'titleBoost.terms', [])
      const boost = _get(filters, 'titleBoost.boost', 10)
      _set(searchBody, 'boost_title_match.terms', terms)
      _set(searchBody, 'boost_title_match.boost', boost)
    }
    if (filters.type === 'tags') {
      if (
        (Object.keys(filters.filters).length === 1 &&
          Object.values(filters.filters)[0].length === 1) ||
        filters?.operator === 'OR'
      ) {
        const operator = _get(filters, 'operator')
        _set(searchBody, 'include_related_tags', filters.include_related_tags)
        _set(searchBody, 'tags_category_operator', operator)
      } else {
        _set(searchBody, 'include_related_tags', false)
        _set(searchBody, 'tags_category_operator', 'AND')
      }
    } else {
      const operator = _get(filters, 'operator')
      _set(searchBody, 'include_related_tags', filters.include_related_tags)
      _set(searchBody, 'tags_category_operator', operator)
    }

    if (filters.exclude_ids) {
      _set(searchBody, 'exclude_ids', filters.exclude_ids)
    }

    // Add likes request
    if (siteName === 'Reports') {
      searchBody.include_likes = true
    }

    return searchBody
  }

  decodeFilters(searchBody) {
    const filters = {}
    let query = _get(searchBody, 'query_string', null)
    if (query === '') {
      query = null
    }
    _set(filters, 'query', query)
    const type = _get(searchBody, 'type', null)
    if (type) {
      _set(
        filters,
        'filters.type',
        type.map(v => this.labelReplacer.getForUI(v, 'type'))
      )
    }
    _set(filters, 'sort', _get(searchBody, 'sort_by', 'publication_date'))
    _set(filters, 'from', _get(searchBody, 'from', 0))
    _set(filters, 'size', _get(searchBody, 'size', 10))
    // Last updated filter
    let lastUpdated = _get(searchBody, 'publication_date', null)
    if (typeof lastUpdated === 'string') {
      lastUpdated = lastUpdated.replace(' ', '_')
      _set(filters, 'filters.publication_date', lastUpdated)
    }
    const contentSettings =
      this.configRepository.getStateConfig('content_settings')
    const validTagCategories = contentSettings?.valid_tag_categories ?? []
    for (let key of validTagCategories) {
      const newkey = key === 'drug_classes' ? 'fw_therapeutic_category' : key
      const val = _get(searchBody, `tags.${newkey}`, []).map(v =>
        this.labelReplacer.getForUI(v, newkey)
      )
      if (val.length > 0) _set(filters, `filters.${newkey}`, val)
    }
    return filters
  }

  bucketToKeyVal(bucket) {
    return bucket.reduce((acc, obj) => {
      // Label replacement
      const bucketKey = this.labelReplacer.getForUI(obj.key)
      return {
        ...acc,
        [bucketKey]: obj.doc_count
      }
    }, {})
  }

  decodeTagSearchResponse(tagsRespose) {
    const tagsState = []

    for (const tag of tagsRespose) {
      let newTag = _get(tag, 'tag', '')
      const category = _get(tag, 'category', '')

      const tagValue = { tag: newTag, category }

      if (newTag) {
        tagsState.push(tagValue)
      }
    }
    return tagsState
  }

  async decodeSearchResponse(response) {
    const state = {}
    if (response.code && response.code === REDIRECT_TO_TAGS_PAGE_STATUS_CODE) {
      _set(state, 'code', response.code)
      _set(state, 'tag', response.tag)
      _set(state, 'results', [])
    } else {
      const results = await this.storyParser.normalizeView(
        _get(response, 'articles', [])
      )
      _set(state, 'results', results)
      _set(state, 'count', _get(response, 'hits', 0))
      _set(state, 'aggs', {})
      _set(state, 'tagFound', response.tagFound)
      const aggs = [...tagMap]

      for (let key of aggs) {
        const newkey = key === 'drug_classes' ? 'fw_therapeutic_category' : key
        const buckets = _get(response, `aggregations.${newkey}.buckets`, [])
        const keyVal = this.bucketToKeyVal(buckets)
        if (Object.keys(keyVal).length > 0) {
          _set(state, `aggs.${newkey}`, keyVal)
        }
      }
      // publication_date agg
      for (let key of lastUpdatedKeysOrder) {
        const newkey = key === 'drug_classes' ? 'fw_therapeutic_category' : key
        const count = _get(
          response,
          `aggregations.publication_date.buckets.${newkey}.doc_count`,
          0
        )
        if (count > 0) _set(state, `aggs.publication_date.${newkey}`, count)
      }
    }
    return state
  }

  parseTagMatchResponse(response) {
    const state = {}

    _set(state, 'tagFound', _get(response, 'tag_found', false))
    _set(state, 'tagMatch', _get(response, 'tag_match', false))

    return state
  }

  parseSavedSearch(response) {
    const state = { ...response }
    for (let key in response.data) {
      const newkey = key === 'drug_classes' ? 'fw_therapeutic_category' : key
      const publication_date = _get(
        state,
        `data[${newkey}].search_request.publication_date`,
        null
      )
      const created = _get(
        state,
        `data[${newkey}].search_request.created`,
        null
      )
      const tags = Array.isArray(response.data[newkey].search_request.tags)
        ? {}
        : response.data[newkey].search_request.tags
      _set(state, `data[${newkey}].search_request.tags`, tags)
      _set(
        state,
        `data[${newkey}].search_request.publication_date`,
        publication_date
      )
      _set(state, `data[${newkey}].search_request.created`, created)
    }
    return state
  }

  encodeSearchNotifyResultsBody(query) {
    const searchBody = {}

    let type = _get(query, 'type')
    if (type && type.length > 0) {
      _set(searchBody, 'type', type)
    }

    _set(searchBody, 'source.id', query?.source?.id)
    _set(searchBody, 'source.title', query?.source?.title)

    let tags = _get(query, 'source.tags')
    if (tags && tags.length > 0) {
      _set(searchBody, 'source.tags', tags)
    }

    if (query?.source?.publication_date) {
      const start_date = query?.source?.publication_date
      _set(
        searchBody,
        'source.publication_date.start',
        query?.source?.publication_date
      )

      // Calculate the timestamp 2 months from the original date
      const parsedStartDate = dayjs.unix(start_date)
      const parsedfutureDate = parsedStartDate.add(2, 'month')
      const end_date = parsedfutureDate.unix()
      _set(searchBody, 'source.publication_date.end', end_date)
    }

    // Get top 3 scored articles
    _set(searchBody, 'size', 3)
    return searchBody
  }

  encodeShareArticleBody(query) {
    const searchBody = {}
    const siteId = process.env.REACT_APP_SITE_ID

    _set(searchBody, 'siteId', parseInt(siteId))

    if (query?.articleId) {
      _set(searchBody, 'articleId', query?.articleId)
    }

    if (query?.email_message) {
      _set(searchBody, 'msg', query?.email_message)
    }

    if (query?.email_address) {
      const emails = query?.email_address?.split(',')

      const parseEmails = emails.map(email => email.trim())

      _set(searchBody, 'emails', parseEmails)
    }
    if (query?.user) {
      _set(searchBody, 'profile', query?.user)
    }

    return searchBody
  }

  encodeShareSiteBody(query) {
    const searchBody = {}
    const siteId = process.env.REACT_APP_SITE_ID

    _set(searchBody, 'siteId', parseInt(siteId))

    if (query?.email_message) {
      _set(searchBody, 'msg', query?.email_message)
    }

    if (query?.email_address) {
      const emails = query?.email_address?.split(',')

      const parseEmails = emails.map(email => email.trim())

      _set(searchBody, 'emails', parseEmails)
    }

    if (query?.user) {
      _set(searchBody, 'profile', query?.user)
    }

    return searchBody
  }
}

export default SearchParser
