import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { SessionContext } from '../../../../modules/Session/context'
import NotifyUpdatesAndValid from './NotifyUpdatesAndValid'
import NotifyUpdatesAndInvalid from './NotifyUpdatesAndInvalid'

const NotifyUpdates = props => {
  const {
    authenticated,
    user,
    checked: sessionReady
  } = useContext(SessionContext)

  const title = props?.item?.title

  if (!sessionReady) {
    return
  }

  if (!authenticated) {
    return <NotifyUpdatesAndInvalid {...props} title={title} />
  }

  return <NotifyUpdatesAndValid {...props} user={user} title={title} />
}

NotifyUpdates.propTypes = {
  story: PropTypes.object,
  item: PropTypes.object
}

export default NotifyUpdates
