import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Spinner from '../../../../components/ui/Spinner'
import BrowseNewsletters from '../BrowseNewsletters'

class MyNewsletters extends Component {
  componentDidMount() {
    this.props.requestListNewsletters()
    this.props.requestListMyNewsletters()
  }

  render() {
    const {
      loadingNewsletters = false,
      loadingMyNewsletters = false,
      newsletters = [],
      subscribed = [],
      hideTopNewslettersSection = false,
      newslettersConfig = {}
    } = this.props

    const configMyNewsletters = newslettersConfig
    return (
      <section>
        {loadingNewsletters || loadingMyNewsletters ? (
          <Spinner fillColor="transparent" strokeColor="rgba(213,213,213,1)" />
        ) : (
          <BrowseNewsletters
            newsletters={newsletters}
            subscribed={subscribed}
            pendingForInsertion={this.props.pendingForInsertion}
            pendingForDeletion={this.props.pendingForDeletion}
            requestDeleteNewsletter={this.props.requestDeleteNewsletter}
            requestInsertNewsletter={this.props.requestInsertNewsletter}
            configMyNewsletters={configMyNewsletters}
            hideTopNewslettersSection={hideTopNewslettersSection}
          />
        )}
      </section>
    )
  }
}

MyNewsletters.propTypes = {
  requestListNewsletters: PropTypes.func,
  pendingForInsertion: PropTypes.array,
  pendingForDeletion: PropTypes.array,
  requestDeleteNewsletter: PropTypes.func,
  requestInsertNewsletter: PropTypes.func,
  requestListMyNewsletters: PropTypes.func,
  loadingNewsletters: PropTypes.bool,
  loadingMyNewsletters: PropTypes.bool,
  newsletters: PropTypes.array,
  subscribed: PropTypes.array,
  hideTopNewslettersSection: PropTypes.bool,
  region: PropTypes.array,
  requestRegion: PropTypes.func,
  newslettersConfig: PropTypes.object
}

export default MyNewsletters
