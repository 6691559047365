import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '../../../../../components/ui/Typography'
import styles from './NoResults.module.sass'
import PropTypes from 'prop-types'

function NoResults({ riverKey }) {
  return (
    <div className={styles.suggestions} data-testid="no-results">
      {riverKey === 'upcoming' ? (
        <Typography type="body2" data-testid="upcoming-message">
          There are currently no upcoming FirstWord Reports available to view.{' '}
          <Link to="/contact-us" className={styles.link}>
            Contact us
          </Link>{' '}
          to learn more about our future reports and how you can gain access as
          soon as they're available.
        </Typography>
      ) : (
        <>
          <p className={styles.none}>
            Your search returned <b>0</b> results:
          </p>
          <Typography type="body2" data-testid="search-message">
            Please review your search for any typos or spelling errors - or try
            a different search term. If you need any assistance, please{' '}
            <Link to="/contact-us" className={styles.link}>
              contact us
            </Link>
            .
          </Typography>
        </>
      )}
    </div>
  )
}

NoResults.propTypes = {
  riverKey: PropTypes.string
}

export default NoResults
