import Cache from 'src/utils/Cache.js'

class CatalogsRepository {
  constructor(Fw5MLApi) {
    this.Fw5MLApi = Fw5MLApi
    this.cache = new Cache()
  }

  async getCatalog(name) {
    if (this.cache.has(name)) return this.cache.get(name)
    const req = await this.Fw5MLApi.fetch(`/datalists`)
    const { data } = req.data
    for (let catalog in data) {
      this.cache.set(catalog, data[catalog])
    }
    if (!data[name]) return undefined
    return data[name]
  }

  async getRegionByCountry(country) {
    const body = {
      country
    }
    const req = await this.Fw5MLApi.fetch(
      `location/getRegionByCountry`,
      'POST',
      body
    )
    return req?.data
  }
}

export default CatalogsRepository
