import React, { useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { actions } from '../../../../modules/SingleStory/reducer'
import { getConfig } from '../../../../modules/Config/selectors'
import PropTypes from 'prop-types'
import ActionIcon from '../ActionIcon'
import RequestAccessModal from '../../../../modules/Paywall/Components/RequestModal'
import EmailConfirmation from '../../../../modules/Paywall/Components/EmailConfirmation'

export const NotifyButton = ({
  user,
  productCode = null,
  articleId = '',
  ...props
}) => {
  const dispatch = useDispatch()
  const notifyMeSettings =
    useSelector(getConfig('notify_me_settings'), shallowEqual) || {}

  const newsletterUuid = notifyMeSettings?.newsletter_notify_me?.newsletter_uuid
  const userIsNotified = user?.notifyMe?.find(
    item =>
      item?.preview_article_ids?.includes(productCode) &&
      item?.newsletter_uuid === newsletterUuid
  )

  const iconPath = !userIsNotified
    ? 'svg/material-design-icons/content/notify'
    : 'svg/material-design-icons/content/notify_clicked'

  const onClick = () => {
    dispatch(
      actions.requestSimpleNotifyMeSubscribe([
        {
          newsletterUuid,
          articleId: productCode
        }
      ])
    )
  }

  return (
    <ActionIcon
      {...props}
      action={onClick}
      iconPath={iconPath}
      articleId={articleId}
      buttonType={!userIsNotified ? 'notify-me' : 'cancel-notify-me'}
    />
  )
}

NotifyButton.propTypes = {
  user: PropTypes.object,
  articleId: PropTypes.any,
  productCode: PropTypes.string
}

export const AnonNotifyActionButton = ({ ...props }) => {
  const { productCode } = props
  const [modalOpen, setModalOpen] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const notifyMeSettings =
    useSelector(getConfig('notify_me_settings'), shallowEqual) || {}
  const newsletterUuid = notifyMeSettings?.newsletter_notify_me?.newsletter_uuid

  const handleOpenModal = () => {
    setModalOpen(true)
  }
  const handleCloseModal = () => {
    setModalOpen(false)
    setIsConfirmationModalOpen(true)
  }

  const notifySettings = {
    newsletterUuid,
    articleId: productCode
  }
  return (
    <>
      <ActionIcon
        {...props}
        action={handleOpenModal}
        iconPath="svg/material-design-icons/content/notify"
      />
      <RequestAccessModal
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        onSuccess={handleCloseModal}
        notifySettings={notifySettings}
      />
      <EmailConfirmation
        open={isConfirmationModalOpen}
        onCancel={() => setIsConfirmationModalOpen(false)}
      />
    </>
  )
}
AnonNotifyActionButton.propTypes = {
  productCode: PropTypes.string
}

const NotifyActionButton = props => {
  if (!props?.authenticated) return <AnonNotifyActionButton {...props} />
  return <NotifyButton {...props} />
}

NotifyActionButton.propTypes = {
  authenticated: PropTypes.bool
}

export default NotifyActionButton
