const StoriesRepository = (Fw5MLApi, StoryParser, SearchParser) => ({
  async single(id) {
    const req = await Fw5MLApi.fetch(`/articles/${id}`)
    const { data: article } = req.data
    return await StoryParser.normalizeArticle(article)
  },

  async search(config, query) {
    const searchBody = SearchParser.encodeSearchBody(config, true)
    const params = new URLSearchParams(query)

    if (query && parseInt(params.get('rst')) === 1)
      searchBody.search_type = 'standard'
    const req = await Fw5MLApi.fetch('/articles/search', 'POST', searchBody)
    const { data } = req.data
    const parsed = await SearchParser.decodeSearchResponse(data)
    return { ...req, data: parsed }
  },

  async reportsSearch(config, query, riverKey) {
    const searchBody = SearchParser.encodeSearchBody(config, true)
    const params = new URLSearchParams(query)

    if (query && parseInt(params.get('rst')) === 1) {
      searchBody.search_type = 'standard'
    }

    if (riverKey === 'upcoming') {
      searchBody.is_upcoming = true
    }

    const req = await Fw5MLApi.fetch(
      '/content/river/reports',
      'POST',
      searchBody
    )

    const { data } = req
    const parsed = await SearchParser.decodeSearchResponse(data)
    const nextFrom = data?.next_from ?? 10

    return { ...req, data: { ...parsed, nextFrom } }
  },

  async downloadReport(productCode) {
    const req = await Fw5MLApi.fetch(
      `/reports/download/${productCode}`,
      'POST',
      {}
    )
    return req.data
  },

  async getProfileReports({ config = {}, customPayload = null }) {
    let searchBody = {}

    if (customPayload) {
      searchBody = customPayload
    } else {
      searchBody = SearchParser.encodeSearchBody(config, true)
    }

    const req = await Fw5MLApi.fetch(
      '/content/river/myReports',
      'POST',
      searchBody
    )
    const data = req.data
    const parsed = await SearchParser.decodeSearchResponse(data)
    return { ...req, data: parsed }
  },

  async getFeaturedContent(config) {
    const { tags } = SearchParser.encodeSearchBody(config)

    const req = await Fw5MLApi.fetch('/articles/featured', 'POST', tags)
    const { data } = req.data
    const parsed = await SearchParser.decodeSearchResponse({ articles: data })
    return { success: true, data: parsed }
  },

  async view(id, params) {
    const body = {
      content_view: [id],
      ...params
    }
    const req = await Fw5MLApi.fetch('/articles', 'POST', body)
    return req.data
  },

  async views(ids, params) {
    const { related_to_article_ids: related, ...rest } = params
    const body = {
      content_view: ids,
      related_to_article_id: related,
      ...rest
    }
    const req = await Fw5MLApi.fetch(`/articles`, 'POST', body)
    return req.data
  },

  // Eventually when we do a clean up and remove the old code, we can only keep listArticles and Search functions here
  async listArticles(params) {
    // This allows empty content views (eg: when they are dynamic and on first render)
    if (!params.content_views.length) {
      return { success: true, data: [] }
    }

    const req = await Fw5MLApi.fetch('/articles', 'POST', {
      ...params
    })
    return req.data
  },

  async listReportArticles(params) {
    if (!params.content_views.length) {
      return { success: true, data: [] }
    }
    const req = await Fw5MLApi.fetch('/content/views/reports', 'POST', {
      ...params
    })
    return req
  },

  async resolveLegacyLinks(fw4_story_id) {
    const req = await Fw5MLApi.fetch(`/articles/legacy_link`, 'POST', {
      legacy_ids: [parseInt(fw4_story_id)]
    })

    const { data } = req.data
    return data[fw4_story_id]
  },

  async searchNotifyMeResults(config) {
    const searchBody = SearchParser.encodeSearchNotifyResultsBody(config)
    const req = await Fw5MLApi.fetch('/notify-me/search', 'POST', searchBody)
    const { data } = req.data

    if (data?.articles && data?.articles?.length > 0) {
      return data?.articles[0]
    }
    return null
  },

  async tagMatch(config, query) {
    const searchBody = SearchParser.encodeSearchBody(config, true)
    const params = new URLSearchParams(query)

    if (query && parseInt(params.get('rst')) === 1)
      searchBody.search_type = 'standard'
    const req = await Fw5MLApi.fetch('/articles/search/tag', 'POST', searchBody)
    const { data } = req.data
    const parsed = await SearchParser.parseTagMatchResponse(data)
    return { ...req, data: parsed }
  },
  async shareArticle(params) {
    const body = await SearchParser.encodeShareArticleBody(params)

    const req = await Fw5MLApi.fetch(
      '/notification/article_share',
      'POST',
      body
    )
    const { data } = req.data
    return { data }
  },
  async shareSite(params) {
    const body = await SearchParser.encodeShareSiteBody(params)

    const req = await Fw5MLApi.fetch(
      '/notification/invite_colleague',
      'POST',
      body
    )
    const { data } = req.data
    return { data }
  },

  async updateLike(articleId) {
    const req = await Fw5MLApi.fetch(`/articles/like/${articleId}`, 'PUT', {})
    return req.data
  },

  async getRelatedReports({ id, ...payload }) {
    const req = await Fw5MLApi.fetch(
      `/content/${id}/relatedReports`,
      'POST',
      payload
    )
    return req.data
  }
})

export default StoriesRepository
