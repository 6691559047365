import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import styles from '../../../../components/SingleStory/SingleStory.module.sass'
import Button from '@mui/material/Button'
import { getConfig } from '../../../../modules/Config/selectors'
import { actions } from '../../../../modules/SingleStory/reducer'
import usePiwik from '../../../../hooks/usePiwik'

export const NotifyUpdatesAndValid = ({ user, title, story }) => {
  const dispatch = useDispatch()
  const { handleTrackingWithArticleIdProp } = usePiwik()

  const notifyMeSettings =
    useSelector(getConfig('notify_me_settings'), shallowEqual) || {}

  const newsletterUuid =
    notifyMeSettings?.newsletter_notify_me_updates?.newsletter_uuid
  const upcomingNewsletterUuid =
    notifyMeSettings?.newsletter_notify_me?.newsletter_uuid

  const productCode = story?.reportSettings?.productCode
  const parentProductCode = story?.reportSettings?.parentProductCode
  const isUpcoming = story?.reportSettings?.upcoming

  const validParentProductCode =
    parentProductCode && parentProductCode !== 'null' ? parentProductCode : null

  // Check if parent product code is not currently in the product code
  const notifyMeId =
    validParentProductCode && !productCode.includes(validParentProductCode)
      ? `${parentProductCode}-${productCode}`
      : productCode

  const userIsNotified = user?.notifyMe?.find(
    item =>
      item?.preview_article_ids?.includes(notifyMeId) &&
      item?.newsletter_uuid === newsletterUuid
  )

  const userIsNotifiedUpcoming = user?.notifyMe?.find(
    item =>
      item?.preview_article_ids?.includes(productCode) &&
      item?.newsletter_uuid === upcomingNewsletterUuid
  )

  const notifyUpdate = () => {
    const notified = !userIsNotified

    const payload = [
      {
        newsletterUuid,
        articleId: notifyMeId,
        notified
      }
    ]
    if (isUpcoming && !!userIsNotified === !!userIsNotifiedUpcoming) {
      payload.push({
        newsletterUuid: upcomingNewsletterUuid,
        articleId: productCode,
        notified
      })
    }

    dispatch(actions.requestSimpleNotifyMeSubscribe(payload))

    handleTrackingWithArticleIdProp(
      'article',
      'button-click',
      notified
        ? 'notify-me-of-future-updates'
        : 'cancel-notify-me-of-future-updates',
      story.id
    )
  }

  return (
    <Button
      className={
        userIsNotified ? styles.notifyActiveSideButton : styles.notifySideButton
      }
      variant="contained"
      onClick={notifyUpdate}
    >
      {userIsNotified ? '✓ You will be notified of future updates' : title}
    </Button>
  )
}

NotifyUpdatesAndValid.propTypes = {
  user: PropTypes.object,
  story: PropTypes.object,
  productCode: PropTypes.string,
  title: PropTypes.string
}

export default NotifyUpdatesAndValid
