import { takeEvery, call, all, put, select } from 'redux-saga/effects'
import { actions } from './reducer.js'
import { actions as NLsActions } from '../../modules/MyNewsletters/reducer.js'
import { notificationDuration as duration } from 'src/constants/index.js'
import { push } from 'connected-react-router'
import { initialize } from 'redux-form'
import { notificationsEnqueue } from 'src/modules/Notifications/reducer.js'
import { logPush } from 'src/modules/ContactUs/reducer.js'
import { logout } from 'src/modules/Session/actions'
import FW5MLError from 'src/services/FW5ML/FW5MLError.js'
import { ACCESS_DENIED } from 'src/services/FW5ML/errorCodes.js'

function* handleRegionUpdate(services, region, userNewsletters) {
  const UserRepository = services('UserRepository')
  const updateTasks = []
  const updatedTasks = []

  const updateNewsletters = userNewsletters.filter(
    newsletter => newsletter?.isRegionBased && newsletter?.hasDeliveryTimes
  )
  if (!updateNewsletters.length) return true

  for (let newsletter of updateNewsletters) {
    const newsletterId = newsletter?.id ?? ''
    const defaultHour = newsletter?.deliveryTimeSettings?.default
    const hasRegion = newsletter?.regions?.find(nl => nl.name === region)

    if (updatedTasks.includes(newsletterId)) continue
    updateTasks.push(
      call(
        [UserRepository, 'updateDeliveryTime'],
        newsletterId,
        hasRegion ? hasRegion?.hour : defaultHour,
        true
      )
    )
    updatedTasks.push(newsletterId)
  }

  yield all(updateTasks)
}
function* handleAccountUpdateError(e, services) {
  const Piwik = services('Piwik')

  if (e instanceof FW5MLError && e.getCode() === ACCESS_DENIED) {
    yield all([
      put(logout({ reload: false, redirect: '/sign-in' })),
      put(
        notificationsEnqueue({
          message: 'Please sign in and try again.'
        })
      )
    ])
    return
  }

  yield all([
    put(
      logPush(
        'Account Update',
        `Error with user profile update in: ${e.message}`
      )
    ),
    put(
      notificationsEnqueue({
        message:
          'Unable to update your profile. Please refresh the page and try again.'
      })
    ),
    call([Piwik, 'track'], 'notification', 'error', 'could-not-update-account'),
    put(actions.errorAccountUpdate(e.message)),
    put(push('/contact-us'))
  ])
}
function* requestAccountUpdate(services, action) {
  const UserRepository = services('UserRepository')
  const Session = services('Session')
  const Piwik = services('Piwik')
  const { payload: account } = action

  try {
    const user = yield select(state => state.session.user) ||
      (yield call([Session, 'userLoad']))
    if (!user) throw new Error('Please sign in and try again.')
    const { profile_id } = user

    // const userRegion = user.region ?? ''
    // let newRegion = userRegion
    // let userNewsletters = []

    // if (user?.address?.country !== account.country) {
    //   const [regionData, userNLs] = yield all([
    //     yield call(
    //       [UserRepository, 'getRegionByCountry'],
    //       account?.country ?? ''
    //     ),
    //     (function* () {
    //       const subscribedNewsletters = yield select(
    //         state => state.myNewsletters?.subscribed
    //       )
    //       if (!subscribedNewsletters || subscribedNewsletters.length === 0) {
    //         return yield call([UserRepository, 'getNewsletters'])
    //       }

    //       return subscribedNewsletters
    //     })()
    //   ])
    //   newRegion = regionData?.data?.data?.[0]?.name ?? ''
    //   userNewsletters = userNLs
    // }

    // if (userRegion !== newRegion) {
    //   account.region = newRegion
    //   yield call(handleRegionUpdate, services, newRegion, userNewsletters)
    // }
    yield call([UserRepository, 'updateAccount'], profile_id, account)

    yield put(actions.successAccountUpdate())
    yield call([Session, 'userUpdate'], account)

    yield all([
      put(
        notificationsEnqueue({
          message: `Your profile has been updated.`,
          duration
        })
      ),
      put(
        initialize('updateAccount', account, false, {
          keepSubmitSucceeded: true
        })
      ),
      call([Piwik, 'track'], 'account', 'update', 'request')
    ])
  } catch (e) {
    handleAccountUpdateError(e, services)
  }
}

export default function* watchUpdate(services) {
  yield all([
    takeEvery('ACCOUNT_UPDATE__REQUEST', requestAccountUpdate, services)
  ])
}
