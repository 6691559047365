import React from 'react'
import Page from '../../../../components/Page'
import List from '../../../../components/ui/List/List'
import ListItem from '../../../../components/ui/List/ListItem'
import Typography from '../../../../components/ui/Typography'
import styles from '../../../../modules/Static/Static.module.sass'
import SectionHeading from '../../../../components/SectionHeading'
import Metadata from '../../../../components/Metadata'
import cx from 'classnames'

const CCPAPrivacyPolicy = () => {
  return (
    <Page className={styles.static}>
      <Metadata title="Supplemental California Consumer Privacy Act Privacy Policy" />
      <SectionHeading
        title="Supplemental California Consumer Privacy Act Privacy Policy"
        gutterBottom
        paragraph
        style={{ textAlign: 'center' }}
      />
      <Typography type="body1" className={styles.paragraph} paragraph>
        In this Supplemental California Consumer Privacy Act Privacy Policy{' '}
        <strong>(“CCPA Privacy Policy”)</strong>, we, Doctor’s Guide Publishing
        Limited, disclose information about our personal information processing
        practices as required by the California Consumer Privacy Act of 2018{' '}
        <strong>(“CCPA”)</strong> and supplement the disclosures in our other
        privacy notices. We use the term <strong>“Services”</strong> to mean the
        websites, mobile applications and online newsletters that we operate.
        This CCPA Privacy Policy is addressed to California residents only and
        was last updated on January 1, 2022. This CCPA Privacy Policy does not
        reflect our collection, use or disclosure of California residents’
        personal information where an exception under the CCPA applies.
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        1. Right to Know About Personal Information Collected, Disclosed, or
        Sold
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        We describe here the personal information we collect, use, disclose and
        sell about California residents. You have the right to request that we
        disclose what personal information we collect, use, disclose and sell
        about you specifically <strong>(“right to know”)</strong>. To submit a
        request to exercise the right to know, please submit an email request to{' '}
        <a className={styles.link} href="mailto:Privacy@firstwordgroup.com">
          Privacy@firstwordgroup.com
        </a>{' '}
        and include “California Request to Know” in the subject line. Please
        specify in your request the details you would like to know, including
        any specific pieces of personal information you would like to access.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        We will ask that you provide certain information to verify your
        identity, such as a code sent to an email address we may have on file
        for you. If you have a password-protected account with us, we may verify
        your identity through our existing authentication practices for your
        account. The information that we ask you to provide to verify your
        identity will depend on your prior interactions with us and the
        sensitivity of the personal information at issue. We will respond to
        your request in accordance with the CCPA. If we deny your request, we
        will explain why.
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        2. Our Personal Information Handling Practices over the Past 12 Months
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        We have set out below the categories of personal information we have
        collected about California residents in the preceding 12 months and, for
        each category of personal information collected, the categories of
        sources from which that information was collected, the business or
        commercial purposes for which the information was collected, and the
        categories of third parties with whom we shared the personal
        information.
      </Typography>
      <table className={styles.tablePrivacyPolicy}>
        <tr>
          <th className={cx(styles.tableItem, styles.tableTh)}>
            <Typography type="subheading" gutterBottom paragraph>
              Category of personal information
            </Typography>
          </th>
          <th className={cx(styles.tableItem, styles.tableTh)}>
            <Typography type="subheading" gutterBottom paragraph>
              We collected such personal information for the following purposes:
            </Typography>
          </th>
          <th className={cx(styles.tableItem, styles.tableTh)}>
            <Typography type="subheading" gutterBottom paragraph>
              We collected such personal information from the following
              categories of sources:
            </Typography>
          </th>
          <th className={cx(styles.tableItem, styles.tableTh)}>
            <Typography type="subheading" gutterBottom paragraph>
              We shared such personal information with the following categories
              of third parties:
            </Typography>
          </th>
        </tr>

        <tr>
          <td className={cx(styles.tableItem, styles.tableTd)}>
            <Typography type="body1" className={styles.paragraph} paragraph>
              Identifiers such as a real name, postal address, unique personal
              identifier, online identifier, Internet Protocol address, email
              address or other similar identifiers.
            </Typography>
          </td>
          <td className={cx(styles.tableListItem, styles.tableTd)}>
            <List
              className={cx(styles.list, styles.tableListContainer)}
              listStyle="outside"
            >
              <ListItem>
                Upon request, create an account for you for the Services;
              </ListItem>
              <ListItem>Respond appropriately to your inquiries;</ListItem>
              <ListItem>Update you regarding your account;</ListItem>
              <ListItem>
                Maintain, secure and improve our Services that we provide you;
              </ListItem>
              <ListItem>
                Provide you with a customized experience in connection with our
                Services;
              </ListItem>
              <ListItem>
                Improve the accuracy and relevance of the results you see when
                you interact with the search engine and the search engine's
                integrated chat-bot functionality that is part of our Services;
              </ListItem>
              <ListItem>
                Discharge our contractual obligations to you;{' '}
              </ListItem>
              <ListItem>
                Comply with any legal obligations that apply to us; and
              </ListItem>
              <ListItem>
                Send you communications according to your preferences.
              </ListItem>
            </List>
          </td>
          <td className={cx(styles.tableListItem, styles.tableTd)}>
            <List
              className={cx(styles.list, styles.tableListContainer)}
              listStyle="outside"
            >
              <ListItem>Directly from you</ListItem>
              <ListItem>
                Directly from the company (e.g. a California resident’s
                employer) sponsoring the FirstWord newsletter subscription
              </ListItem>
            </List>
          </td>
          <td className={cx(styles.tableListItem, styles.tableTd)}>
            <List
              className={cx(styles.list, styles.tableListContainer)}
              listStyle="outside"
            >
              <ListItem>
                We may share your personal information with affiliated and
                non-affiliated service providers to help us fulfill the purposes
                set forth in this Privacy Policy
              </ListItem>
              <ListItem>
                We may disclose your personal information to government entities
                as required by applicable law or necessary to protect against
                harm
              </ListItem>
              <ListItem>
                If you respond to a market research survey, we do not share your
                personal information with any third parties.
              </ListItem>
            </List>
          </td>
        </tr>

        <tr>
          <td className={cx(styles.tableItem, styles.tableTd)}>
            <Typography type="body1" className={styles.paragraph} paragraph>
              Any categories of personal information described in subdivision
              (e) of Section 1798.80: “Personal information” means any
              information that identifies, relates to, describes, or is capable
              of being associated with, a particular individual, including, but
              not limited to, his or her name, address, telephone number,
              education, employment, employment history.
            </Typography>
          </td>
          <td className={cx(styles.tableItem, styles.tableTd)}>
            <Typography type="body1" className={styles.paragraph} paragraph>
              Same as above
            </Typography>
          </td>
          <td className={cx(styles.tableItem, styles.tableTd)}>
            <Typography type="body1" className={styles.paragraph} paragraph>
              Same as above
            </Typography>
          </td>
          <td className={cx(styles.tableItem, styles.tableTd)}>
            <Typography type="body1" className={styles.paragraph} paragraph>
              Same as above
            </Typography>
          </td>
        </tr>

        <tr>
          <td className={cx(styles.tableItem, styles.tableTd)}>
            <Typography type="body1" className={styles.paragraph} paragraph>
              Internet or other electronic network activity information,
              including, but not limited to, search history, and information
              regarding a California resident’s interaction with an Internet Web
              site, application, or advertisement.
            </Typography>
          </td>
          <td className={cx(styles.tableListItem, styles.tableTd)}>
            <List
              className={cx(styles.list, styles.tableListContainer)}
              listStyle="outside"
            >
              <ListItem>
                Upon request, create an account for you for the Services;
              </ListItem>
              <ListItem>Update you regarding your account;</ListItem>
              <ListItem>
                Maintain, secure and improve our Services that we provide you;
              </ListItem>
              <ListItem>
                Provide you with a customized experience in connection with our
                Services;
              </ListItem>
              <ListItem>
                Improve the accuracy and relevance of the results you see when
                you interact with the search engine and the search engine's
                integrated chat-bot functionality that is part of our Services;
              </ListItem>
              <ListItem>
                Discharge our contractual obligations to you; and
              </ListItem>
              <ListItem>
                Comply with any legal obligations that apply to us.
              </ListItem>
            </List>
          </td>
          <td className={cx(styles.tableItem, styles.tableTd)}>
            <Typography type="body1" className={styles.paragraph} paragraph>
              Directly from you
            </Typography>
          </td>
          <td className={cx(styles.tableItem, styles.tableTd)}>
            <Typography type="body1" className={styles.paragraph} paragraph>
              Same as above
            </Typography>
          </td>
        </tr>

        <tr>
          <td className={cx(styles.tableItem, styles.tableTd)}>
            <Typography type="body1" className={styles.paragraph} paragraph>
              Electronic or similar information.
            </Typography>
          </td>
          <td className={cx(styles.tableItem, styles.tableTd)}>
            <Typography type="body1" className={styles.paragraph} paragraph>
              We collect and store personal information electronically for the
              purposes described in this Privacy policy
            </Typography>
          </td>
          <td className={cx(styles.tableItem, styles.tableTd)}>
            <Typography type="body1" className={styles.paragraph} paragraph>
              Directly from you
            </Typography>
          </td>
          <td className={cx(styles.tableItem, styles.tableTd)}>
            <Typography type="body1" className={styles.paragraph} paragraph>
              Same as above
            </Typography>
          </td>
        </tr>

        <tr>
          <td className={cx(styles.tableItem, styles.tableTd)}>
            <Typography type="body1" className={styles.paragraph} paragraph>
              Inferences drawn from any of the information identified in this
              subdivision to create a profile about a California resident
              reflecting the California resident's preferences; and/or
              characteristics.
            </Typography>
          </td>
          <td className={cx(styles.tableListItem, styles.tableTd)}>
            <List
              className={cx(styles.list, styles.tableListContainer)}
              listStyle="outside"
            >
              <ListItem>
                Provide you with a customized experience in connection with our
                Services; and
              </ListItem>
              <ListItem>
                Send you communications according to your preferences.
              </ListItem>
            </List>
          </td>
          <td className={cx(styles.tableItem, styles.tableTd)}>
            <Typography type="body1" className={styles.paragraph} paragraph>
              Directly from you
            </Typography>
          </td>
          <td className={cx(styles.tableItem, styles.tableTd)}>
            <Typography type="body1" className={styles.paragraph} paragraph>
              Same as above
            </Typography>
          </td>
        </tr>
      </table>
      <Typography type="subheading" gutterBottom paragraph>
        3. Disclosures of Personal Information; No Sale
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        Over the preceding 12 months, we disclosed certain categories of
        California residents’ personal information to the categories of third
        parties as shown in the table above.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        We do not sell California residents’ personal information. We do not
        have actual knowledge that we sell the personal information of minors
        under 16 years of age.
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        4. Right to Request Deletion of Personal Information
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        You have a right to request the deletion of personal information that we
        collected from you. To submit a request to delete personal information,
        please submit an email request to{' '}
        <a className={styles.link} href="mailto:Privacy@firstwordgroup.com">
          Privacy@firstwordgroup.com
        </a>{' '}
        and include “California Request to Delete” in the subject line. You may
        also submit a request by mailing us at 1140 Avenue of the Americas, 14th
        Floor, New York, NY 10036. Please specify in your request the personal
        information about you that you would like to have deleted, which can be
        all of the personal information we have collected from you as required
        by the CCPA.
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        We will ask that you provide certain information to verify your
        identity, such as a code sent to an email address we may have on file
        for you. If you have a password-protected account with us, we may verify
        your identity through our existing authentication practices for your
        account. The information that we ask you to provide to verify your
        identity will depend on your prior interactions with us and the
        sensitivity of the personal information at issue. Once we have verified
        your identity, we may ask you to confirm that you wish to have your
        personal information deleted. Once confirmed, we will respond to your
        request in accordance with the CCPA. If we deny your request, we will
        explain why.
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        5. Right to Opt-Out of the Sale of Personal Information
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        You have the right to opt-out of the sale of your personal information
        by a business. We do not sell your personal information.
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        6. Right to Non-Discrimination for the Exercise of CCPA Rights
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        You may not be discriminated against because you exercise any of your
        rights under the CCPA.
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        7. Authorized Agent
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        You can designate an authorized agent to make a request under the CCPA
        on your behalf if:
      </Typography>
      <List className={styles.list} listStyle="outside">
        <ListItem>
          The authorized agent is a natural person or a business entity
          registered with the Secretary of State of California; and
        </ListItem>
        <ListItem>
          You sign a written declaration that you authorize the authorized agent
          to act on your behalf.
        </ListItem>
      </List>
      <Typography type="body1" className={styles.paragraph} paragraph>
        If you use an authorized agent to submit a request to exercise your
        right to know or your right to request deletion, please have the
        authorized agent take the following steps in addition to the steps
        described in Sections 1 and 4 above:
      </Typography>
      <List className={styles.list} listStyle="outside">
        <ListItem>
          Mail a notarized copy of your written declaration authorizing the
          authorized agent to act on your behalf to 1140 Avenue of the Americas,
          14th Floor, New York, NY 10036 (we will reimburse you for the costs of
          notarizing the declaration and mailing it to us if you provide a
          receipt showing the costs paid and they are reasonable); and
        </ListItem>
        <ListItem>
          Provide any information we request in our response to your email to
          verify your identity and that you provided the authorized agent
          permission to submit a request under the CCPA on your behalf. The
          information that we ask you to provide to verify your identity will
          depend on your prior interactions with us and the sensitivity of the
          personal information at issue.
        </ListItem>
      </List>
      <Typography type="body1" className={styles.paragraph} paragraph>
        If you provide an authorized agent with power of attorney pursuant to
        Probate Code sections 4000 to 4465, it may not be necessary to perform
        these steps and we will respond to any request from such authorized
        agent in accordance with the CCPA.
      </Typography>
      <Typography type="subheading" gutterBottom paragraph>
        8. Contact for More Information
      </Typography>
      <Typography type="body1" className={styles.paragraph} paragraph>
        If you have questions or concerns regarding our privacy policy or
        practices, you may email us at{' '}
        <a className={styles.link} href="mailto:Privacy@firstwordgroup.com">
          Privacy@firstwordgroup.com
        </a>
        .
      </Typography>
    </Page>
  )
}
export default CCPAPrivacyPolicy
